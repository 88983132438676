.grAside {
    position: relative;
    border-radius: 4px;
    margin-bottom: var(--doubleGap);
    background-color: white;
    box-shadow: 0 10px 20px rgba(0,0,0,.1);
    @include breakpoint('max-lg') {
        margin-top: var(--doubleGap);
    }
}

.grAside:after {
    content: '';
    display: block;
    width: 150px; height: 150px;
    position: absolute;
    top: -52px; left: 25px;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxMTAgODYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM6c2VyaWY9Imh0dHA6Ly93d3cuc2VyaWYuY29tLyIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoyOyI+PHJlY3QgaWQ9IkFydGJvYXJkMSIgeD0iMCIgeT0iMCIgd2lkdGg9IjEwOS40MjIiIGhlaWdodD0iODUuMDI3IiBzdHlsZT0iZmlsbDpub25lOyIvPjxwYXRoIGQ9Ik0xNC42NjMsODIuMzAybDExLjI3NSwwbDI0LjQ0NiwtMzQuNDQ1bC0wLC00NC4xNTlsLTQ2Ljg4NCwtMGwtMCw0Ni44ODNsMjAuOTY5LDBjLTAsMCAtMTMuMzYxLDI1Ljg4NyAtMTMuMzYxLDI1Ljg4N2wzLjU1NSw1LjgzNFptNTYuMTE2LDBsMTEuMjc2LDBsMjQuNDQ1LC0zNC40NDVsMCwtNDQuMTU5bC00Ni44ODQsLTBsMCw0Ni44ODNsMjAuOTY5LDBjMCwwIC0xMy4zNiwyNS44ODcgLTEzLjM2LDI1Ljg4N2wzLjU1NCw1LjgzNFptLTQ2LjkwNywtNGwyMi41MTIsLTMxLjcyMWwtMCwtMzguODgzbC0zOC44ODQsLTBsLTAsMzguODgzbDIzLjUzNSwwbC0xNi4zNzIsMzEuNzIxbDkuMjA5LDBabTU2LjExNiwwbDIyLjUxMiwtMzEuNzIxbDAsLTM4Ljg4M2wtMzguODg0LC0wbDAsMzguODgzbDIzLjUzNSwwbC0xNi4zNzIsMzEuNzIxbDkuMjA5LDBaIiBzdHlsZT0iZmlsbDp1cmwoI19MaW5lYXIxKTsiLz48ZGVmcz48bGluZWFyR3JhZGllbnQgaWQ9Il9MaW5lYXIxIiB4MT0iMCIgeTE9IjAiIHgyPSIxIiB5Mj0iMCIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIGdyYWRpZW50VHJhbnNmb3JtPSJtYXRyaXgoODkuNDg4NCwwLDAsMTM1LjI3Miw0Ljk4ODM2LDcuNDAzMSkiPjxzdG9wIG9mZnNldD0iMCIgc3R5bGU9InN0b3AtY29sb3I6IzAwN2FiMztzdG9wLW9wYWNpdHk6MSIvPjxzdG9wIG9mZnNldD0iMC41IiBzdHlsZT0ic3RvcC1jb2xvcjojMzBjYmM3O3N0b3Atb3BhY2l0eToxIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdHlsZT0ic3RvcC1jb2xvcjojODJmNzRmO3N0b3Atb3BhY2l0eToxIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PC9zdmc+");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @include breakpoint('lg') {    
        top: -30px; left: -20px;    
        width: 100px; height: 100px;
    }
}

.grAside__image {
    position: relative;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
}

.grAside__image img {
    display: block;
    width: 100%;
}

.grAside__text {
    padding: var(--gap) var(--doubleGap);
    font-size: var(--smallFont);
}
