:root {

    // dimentions:
    --gap: 20px;
    --doubleGap: 40px;
    --tripleGap: 60px;
    --buttonHeight: 40px;
    --sectionPadding: 120px;
    --headerHeightMobile: 205px;
    --headerHeightDesktop: 102px;
    
    // font sizes:
    --defaultFont: 20px;
    --smallFont: 16px;
    --smallerFont: 14px;

    //colors:
    --c1: rgb(0,122,179);
    --c2: rgb(48,203,199);
    --c3: rgb(130,247,79);
    --c4: rgb(16,60,69);
    --c5: #e4ffd9;
    --c6: #e4f0f7;
    
    --gray1: rgb(240,240,240);
    --gray2: rgba(221,221,221);
    --gradient: linear-gradient(90deg, rgb(0,122,179) 0%, rgb(48,203,199) 50%, rgb(130,247,79) 100%);
}