.grButton  {
    $border: 3px;
    display: inline-flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    background-clip: padding-box;
    background-color: white;
    border: $border solid transparent;
    border-radius: 25px;
    height: var(--buttonHeight);
    padding: var(--gap) var(--doubleGap);
    color: black;
    text-decoration: none;
    font-size: var(--smallFont);
    font-weight: bold;
    z-index: 1;
  
    &:before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: -1;
      margin: -$border;
      border-radius: inherit;
      background: linear-gradient(90deg, rgba(0,122,179,1) 0%, rgba(48,203,199,1) 50%, rgba(130,247,79,1) 100%);
    }
  
    &:after {
      content: '';
      position: absolute;
      inset: 3px;
      z-index: -1;
      margin: -$border;
      border-radius: inherit;
      background: white;
      transition: background-color .5s, color .5s;  
    }
  
    &:hover {
      cursor: pointer;
      color: white;
      &:after {
        background-color: black;
      }
    }
  
  }

  .grButton--fluid {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .grButton--green {
    background-color: var(--c3);
    transition: background-color .5s;
    &:before, &:after {
      display: none;
    }
    &:hover {
      color: black;
      background-color: var(--c2);
    }
  }


  
.grCloseBtn {
  position: absolute;
  top: var(--gap);
  right: var(--gap);
  background-color: transparent;
  border: 0;
  display: block;
  width: 40px; height: 40px;
  border-radius: 50%;
  transition: background-color .5s;
  background-color: var(--c1);
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxNiAxNiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij48cmVjdCBpZD0iQXJ0Ym9hcmQxIiB4PSIwIiB5PSIwIiB3aWR0aD0iMTUuNTU2IiBoZWlnaHQ9IjE1LjU1NiIgc3R5bGU9ImZpbGw6bm9uZTsiLz48cGF0aCBkPSJNOS4xOTIsNy43NzhsNi4zNjQsLTYuMzY0bC0xLjQxNCwtMS40MTRsLTYuMzY0LDYuMzY0bC02LjM2NCwtNi4zNjRsLTEuNDE0LDEuNDE0bDYuMzY0LDYuMzY0bC02LjM2NCw2LjM2NGwxLjQxNCwxLjQxNGw2LjM2NCwtNi4zNjRsNi4zNjQsNi4zNjRsMS40MTQsLTEuNDE0bC02LjM2NCwtNi4zNjRaIiBzdHlsZT0iZmlsbDojZmZmOyIvPjwvc3ZnPg==");
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;

  &:hover {
    background-color: var(--c4);
    cursor: pointer;
  }
}