.grHeader {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--headerHeightMobile);
    padding: 30px 0 25px 0;
    box-sizing: border-box;
    background-color: transparent;
    background-color: white;
    transition: background-color 1s, transform .5s;
    box-shadow: 0 10px 50px rgba(0,0,0,.1);

    @include breakpoint('md') {
        height: var(--headerHeightDesktop);
    }
}


.grHeader--hidden {
    transform: translateY(-100%);
}

.grHeader:before {
    content: "";
    display: block;
    width: 100%;
    height: 6px;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--gradient);
}

.grHeaderContainer {

    @include breakpoint('max-md') {
        text-align: center;
    }

    @include breakpoint('md') {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
    }

    @include breakpoint('max-md') {
        margin-top: var(--gap);
        justify-content: space-between;
    }
}

.grHeaderIssue {
    margin-right: var(--gap);
    font-size: var(--smallFont);

    @include breakpoint('max-md') {
        margin-right: 0;
        margin-bottom: var(--gap);
    }

}

.grHeaderSidebar {
    display: block;
    width: 460px;
    height: 100%;
    padding: 70px var(--gap) var(--doubleGap) var(--doubleGap);
    box-sizing: border-box;
    position: fixed;
    top: 0; 
    right: 0;
    z-index: 100;
    background-color: var(--c4);
    transform: translateX(200%);
    transition: transform .5s;
    color: white;
    max-width: 100%;
    
}

.grHeaderSidebar--active {
    transform: translateX(0);
}

.grHeaderSidebar__close {
    position: absolute;
    top: var(--gap);
    right: var(--gap);
    background-color: transparent;
    border: 0;
    display: block;
    width: 40px; height: 40px;
    border-radius: 50%;
    transition: background-color .5s;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxNiAxNiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij48cmVjdCBpZD0iQXJ0Ym9hcmQxIiB4PSIwIiB5PSIwIiB3aWR0aD0iMTUuNTU2IiBoZWlnaHQ9IjE1LjU1NiIgc3R5bGU9ImZpbGw6bm9uZTsiLz48cGF0aCBkPSJNOS4xOTIsNy43NzhsNi4zNjQsLTYuMzY0bC0xLjQxNCwtMS40MTRsLTYuMzY0LDYuMzY0bC02LjM2NCwtNi4zNjRsLTEuNDE0LDEuNDE0bDYuMzY0LDYuMzY0bC02LjM2NCw2LjM2NGwxLjQxNCwxLjQxNGw2LjM2NCwtNi4zNjRsNi4zNjQsNi4zNjRsMS40MTQsLTEuNDE0bC02LjM2NCwtNi4zNjRaIiBzdHlsZT0iZmlsbDojZmZmOyIvPjwvc3ZnPg==");
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;

    &:hover {
        background-color: rgba(0,0,0,0.3);
        cursor: pointer;
    }
}

.grNav {
    padding-right: var(--gap);
    max-height: calc(100vh - 190px);
    overflow-y: scroll;
}

.grNav__link {
    color: white;
    text-decoration: none;
    transition: color .5s;
    &:hover {
        color: var(--c3);
    }
}

.grNav__item {
    margin-top: var(--gap);
    padding-top: var(--gap);
    border-top: 1px solid rgba(0,0,0,.25);
}


.grHeaderSidebar__footer {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    padding: var(--doubleGap);
    bottom:0;
    left: 0;
}


.grHeaderPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 700px;
    height: 500px;
    transform: translate(-50%, -50%);
    background: white;
    box-shadow: 0 5px 50px rgba(0,0,0,0.75);
    border-radius: 5px;
    transition: top 1s;
}

.grHeaderPopup__header {
    display: flex;
    flex-flow: row-reverse;
    padding: var(--doubleGap) var(--doubleGap) 0 var(--doubleGap);
}


.grHeaderPopup__content {
    padding: var(--doubleGap);
}

.grArchive {
    overflow-y: scroll;
    max-height: 500px;
}


.grArchive__item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-block: var(--gap);
    border-bottom: 1px solid var(--gray1);
}



.grSimpleHeader {
    z-index: 1;
    width: 100%;
    padding: 30px 0 25px 0;
    box-sizing: border-box;
    background-color: transparent;
    background-color: white;
    transition: background-color 1s, transform .5s;
    box-shadow: 0 10px 50px rgba(0,0,0,.1);
}
.grSimpleHeader:before {
    content: "";
    display: block;
    width: 100%;
    height: 6px;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--gradient);
}