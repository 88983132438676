.grIntro {
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: var(--headerHeightMobile);
    @include breakpoint('lg') {
        padding-top: var(--headerHeightDesktop);
    }
    &:before {
        content: "";
        position: absolute;
        top: 0; left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,.5) 100%);
        z-index: 1;
    }
}

.grIntroText {
    position: relative;
    z-index: 1;
    padding-top: var(--sectionPadding);
    @include breakpoint('lg') {
        padding-top: var(--sectionPadding);
    }
}

.grIntroText h1 {
    color: white;
    font-size: 44px;
    line-height: 1;
    margin-bottom: var(--doubleGap);
    @include breakpoint('md') {
        font-size: 72px;    
        margin-bottom: var(--tripleGap);
    }
    @include breakpoint('lg') {
        padding-right: 12vw;
    }
}

.grIntro h1 span {
    color: var(--c3);
}

.grIntroBox {
    z-index: 1;
    box-sizing: border-box;
    padding: var(--doubleGap) var(--doubleGap)  var(--tripleGap) var(--doubleGap);
    color: white;
    background-color: var(--c4);
    position: relative;
    margin-bottom: -32px;
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 32px;
        position: absolute;
        bottom: 0; left: 0;
        background: var(--gradient);
    }
    @include breakpoint('lg') {    
                padding: var(--gap) 220px 52px var(--doubleGap);        
                mask-image: url(../../assets/ui/mask.svg);
                mask-size: cover;
                mask-repeat: no-repeat;
                mask-position: top right;
            }

}

// .grIntro {
//     background-position: center center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     position: relative;
//     &:before {
//         content: "";
//         position: absolute;
//         top: 0; left: 0;
//         display: block;
//         width: 100%;
//         height: 100%;
//         background: linear-gradient(270deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,.5) 100%);
//         z-index: 1;
//     }
// }

// .grIntro h1 {
//     padding-right: 12vw;
//     color: white;
//     font-size: 44px;
//     line-height: 1;
//     margin-bottom: 50px;
//     @include breakpoint('lg') {
//         font-size: 72px;    
//         margin-bottom: 80px;
//     }
// }

// .grIntro h1 span {
//     color: var(--c3);
// }

// .grIntroText {
//     position: relative;
//     z-index: 2;
//     display: flex;
//     flex-flow: column nowrap;
//     justify-content: flex-end;
//     height: 780px;
// }

// .grIntroBox {
//     box-sizing: border-box;
//     padding: var(--doubleGap) var(--doubleGap)  var(--tripleGap) var(--doubleGap);
//     color: white;
//     background-color: var(--c4);
//     position: relative;
//     margin-bottom: -32px;
//     &:after {
//         content: '';
//         display: block;
//         width: 100%;
//         height: 32px;
//         position: absolute;
//         bottom: 0; left: 0;
//         background: var(--gradient);
//     }

//     @include breakpoint('lg') {    
//         padding: var(--gap) 220px 52px var(--doubleGap);        
//         mask-image: url(../../assets/ui/mask.svg);
//         mask-size: cover;
//         mask-repeat: no-repeat;
//         mask-position: top right;
//     }

// }