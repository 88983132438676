$breakpoints: (
  'xs':  ( min-width:  480px ),
  'sm':  ( min-width:  576px ),
  'md': ( min-width:  768px ),
  'lg':  ( min-width: 992px ),
  'xl':  ( min-width: 1200px ),
  'max-xs': ( max-width:  479px ),
  'max-sm': ( max-width:  575px ),
  'max-md': ( max-width:  767px ),
  'max-lg': ( max-width:  991px ),
  'max-xl': ( max-width:  1199px )
) !default;

@mixin breakpoint($bp) {
  @if map-has-key($breakpoints, $bp) {
    @media #{inspect(map-get($breakpoints, $bp))} {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$bp}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
