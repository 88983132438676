// basics
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');
@import './styles/basics/variables.scss';
@import './styles/basics/normalize.scss';
@import './styles/basics/mixins.scss';
@import './styles/basics/typography.scss';
@import './styles/basics/bootstrap.scss';

// elements
@import './styles//elements/logo.scss';
@import './styles//elements/button.scss';
@import './styles/elements/section.scss';
@import './styles//elements/aside.scss';
@import './styles//elements/image.scss';
@import './styles//elements/youtube.scss';

// components
@import './styles/components/siteHeader.scss';
@import './styles/components/siteFooter.scss';
@import './styles//components/intro.scss';
@import './styles//components/gallery.scss';

html { scroll-behavior: smooth; }

.App {
    position: relative;
 }

.container {
    box-sizing: border-box;
}


