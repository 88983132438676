.grFooter {
    position: relative;
    padding: var(--sectionPadding) 0;
    color: white;
    background-color: var(--c4);
}

.grFooter:before {
    content: "";
    display: block;
    width: 100%;
    height: 6px;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--gradient);
}

.grFooterSocialMedia {
    display: flex;
    flex-flow: row nowrap;
    width: 100%; 
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    @include breakpoint('lg') {
        justify-content: flex-end;
    }
}

.grFooterButton {
    position: relative;
    display: block;
    width: 74px; 
    height: 74px;
    margin-inline: 0 var(--doubleGap);
    padding: 4px;
    border-radius: 50%;
    @include breakpoint('lg') {        
       margin-inline: var(--doubleGap) 0;
    }
    &:hover {
        background: var(--gradient);
    }
}
