.grImage {
    border-radius: 5px;
    border: 10px solid var(--gray2);
    overflow: hidden;
    box-sizing: border-box;
    margin-block: var(--gap);
}
.grImage img {
    display: block;
    width: 100%;
}