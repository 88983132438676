body, button {
    font-family: 'Inter', sans-serif;
    font-size: var(--defaultFont);
}

button {
    font-weight: bold;
    font-size: var(--smallFont);
}

h1, h2, h3, h4 {
    margin: var(--gap) 0;
    font-weight: 400;
    line-height: 1.25;
}

h1 {
    font-size: 8vw;
    @media(min-width:767px ) {
        font-size: 60px;
    }
}

h2 {
    font-size: 4vw;
    @media(min-width:767px ) {
        font-size:36px;
    }
}

strong {
    font-weight: 600;
}

 p, li {
    line-height: 1.5;
}
p {
    margin: var(--gap) 0;
}

.grText {
    background: var(--gradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}