.slick-slider {
    position: relative;
    margin-bottom: var(--tripleGap);
}

.slick-list {
    overflow: hidden;
}
.slick-track {
    display: flex;
}

.slick-dots {
    min-height: 20px;
    list-style: none;
    margin: 0; padding: 0;
}

.slick-dots li { 
    float: left; 
    margin: 0 10px 10px 0;
}


.slick-dots button { 
    display: block;
    width: 20px; height: 20px;
    text-indent: -9999px;
    background-color: var(--gray2);
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color .5s;
}

.slick-dots .slick-active button { 
    background-color: var(--c1);
}


.slick-arrow {
    display: block;
    width: 24px; height: 24px;
    position: absolute;
    bottom: -3px; 
    text-indent: -9999px;
    border: 0;
    border-radius: 50%;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px;
    cursor: pointer;
    @media (max-width: 991px) {
        bottom: -20px;
    }
}

.slick-arrow.slick-prev {
    right: 40px;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxNyAyOSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij48cmVjdCBpZD0iQXJ0Ym9hcmQxIiB4PSIwIiB5PSIwIiB3aWR0aD0iMTYuNDE2IiBoZWlnaHQ9IjI4LjU4OSIgc3R5bGU9ImZpbGw6bm9uZTsiLz48cGF0aCBkPSJNMTYuNDE2LDIuMTIxbC0xMi4xNzMsMTIuMTc0Yy0wLC0wIDEyLjE3MywxMi4xNzMgMTIuMTczLDEyLjE3M2wtMi4xMjEsMi4xMjFsLTE0LjI5NSwtMTQuMjk0bDE0LjI5NSwtMTQuMjk1bDIuMTIxLDIuMTIxWiIvPjwvc3ZnPg==");
}

.slick-arrow.slick-next {
    right: 0;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxNyAyOSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij48cmVjdCBpZD0iQXJ0Ym9hcmQxIiB4PSIwIiB5PSIwIiB3aWR0aD0iMTYuNDE2IiBoZWlnaHQ9IjI4LjU4OSIgc3R5bGU9ImZpbGw6bm9uZTsiLz48cGF0aCBkPSJNMCwyLjEyMWwxMi4xNzMsMTIuMTc0YzAsLTAgLTEyLjE3MywxMi4xNzMgLTEyLjE3MywxMi4xNzNsMi4xMjEsMi4xMjFsMTQuMjk1LC0xNC4yOTRsLTE0LjI5NSwtMTQuMjk1bC0yLjEyMSwyLjEyMVoiLz48L3N2Zz4=");
  
}